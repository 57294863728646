import type {Address, City, Country, County} from "~/types/Address";
import {createDefaultAddress} from "~/types/Address";

export interface VehicleType {
    id: number | null;
    code: string | null;
    name: string | null;
}

export interface VehicleCategory {
    id: number | null;
    name: string | null;
    ws_id: number | null;
    code: string | null;
}

export interface VehicleMake {
    id: number | null;
    name: string | null;
    rca_mapped_category_id: number | null;
    rca_mapped_category: VehicleCategory | null;
}

export interface VehicleModel {
    id: number | null;
    name: string | null;
    rca_vehicle_make_id: number | null;
}

export interface VehicleFuelType {
    id: number | null;
    code: string | null;
    name: string | null;
}

export interface VehicleUsageType {
    id: number | null;
    name: string | null;
    code: string | null;
}

export interface LeasingCompany {
    id: number | null;
    name: string | null;
}

export interface BasicVehicle {
    vehicle_type: VehicleType | null;
    rca_mapped_category: VehicleCategory | null;
    registration_plate: string | null;
    vin: string | null;
    make: VehicleMake | null;
    model: VehicleModel | null;
    number_of_seats: number | null;
    max_weight: number | null;
    manufacture_year: number | null;
}

export const createDefaultBasicVehicle = (): BasicVehicle => {
    return {
        rca_mapped_category: {
            id: 3,
            name: "Autoturism / Automobil mixt",
            ws_id: 1,
            code: "car",
        },
        vehicle_type: {
            id: 1,
            code: "registered",
            name: "Inmatriculat",
        },
        registration_plate: null,
        vin: null,
        make: null,
        model: null,
        number_of_seats: null,
        max_weight: null,
        manufacture_year: null,
    }
}

export interface ValidationBasicVehicleStatus {
    vehicle_type: boolean;
    rca_mapped_category: boolean;
    registration_plate: boolean;
    vin: boolean;
    make: boolean;
    model: boolean;
    number_of_seats: boolean;
    max_weight: boolean;
    manufacture_year: boolean;

    [key: string]: boolean | boolean[];
}

export interface RcaVehicleForm extends BasicVehicle {
    id: number | null;
    civ_code: string;
    usage_type: VehicleUsageType | null;
    fuel_type: VehicleFuelType | null;
    cylinder: number | null;
    power: number | null;
    is_vehicle_in_leasing: boolean | null;
    leasing_company: LeasingCompany | null;
    address: Address;
}

export const createDefaultRcaVehicleForm = (): RcaVehicleForm => {
    return {
        id: null,
        registration_plate: '',
        vin: '',
        vehicle_type: {
            id: 1,
            code: 'registered',
            name: 'Inmatriculat',
        },
        civ_code: '',
        rca_mapped_category: {
            id: 3,
            name: 'Autoturism / Automobil mixt',
            ws_id: 1,
            code: "car",
        },
        usage_type: {
            id: 1,
            name: 'Personal',
            code: 'personal',
        },
        make: null,
        model: null,
        manufacture_year: null,
        fuel_type: null,
        cylinder: null,
        power: null,
        max_weight: null,
        number_of_seats: null,
        is_vehicle_in_leasing: false,
        leasing_company: null,
        address: <Address>createDefaultAddress(),
    }
}

export interface RcaVehicleFormValidation {
    registration_plate: boolean;
    vin: boolean;
    vehicle_type: boolean;
    civ_code: boolean;
    rca_mapped_category: boolean;
    usage_type: boolean;
    make: boolean;
    model: boolean;
    manufacture_year: boolean;
    fuel_type: boolean;
    cylinder: boolean;
    power: boolean;
    max_weight: boolean;
    number_of_seats: boolean;
    is_vehicle_in_leasing: boolean;
    leasing_company: boolean;

    [key: string]: boolean | boolean[]; // Add index signature
}

export const createDefaultRcaVehicleFormValidation = (): RcaVehicleFormValidation => {
    return {
        registration_plate: false,
        vin: false,
        vehicle_type: false,
        civ_code: false,
        rca_mapped_category: false,
        usage_type: false,
        make: false,
        model: false,
        manufacture_year: false,
        fuel_type: false,
        cylinder: false,
        power: false,
        max_weight: false,
        number_of_seats: false,
        is_vehicle_in_leasing: false,
        leasing_company: false,
    }
}

export interface ApiVehicleDetails {
    id: number | null;
    registration_plate: string | null;
    vin: string | null;
    manufacture_year: string | null;
    max_weight: number | null;
    cylinder: number | null;
    power: number | null;
    number_of_seats: number | null;
    civ_code: string | null;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
    vehicle_rar_category_id: number | null;
    rca_mapped_category_id: number | null;
    rca_vehicle_make_id: number | null;
    rca_vehicle_model_id: number | null;
    rca_leasing_company_id: number | null;
    vehicle_fuel_type_id: number | null;
    vehicle_usage_type_id: number | null;
    user_id: number | null;
    ro_vignette_category_id: number | null;
    hu_vignette_category_id: number | null;
    vehicle_type_id: number | null;
    color: string | null;
    rca_vehicle_make_name: string | null;
    rca_vehicle_model_name: string | null;
    vehicle_type: VehicleType | null;
    default_vehicle_address: ApiVehicleAddress | null | undefined;
    vehicle_owner: ApiVehicleOwner | null | undefined;
    default_vehicle_expiration: string | null;
    rca_vehicle_make: VehicleMake | null | undefined;
    rca_vehicle_model: VehicleModel | null | undefined;
    vehicle_fuel_type: VehicleFuelType | null | undefined;
    vehicle_usage_type: VehicleUsageType | null | undefined;
    rca_mapped_category: VehicleCategory | null | undefined;
    rca_leasing_company: RcaLeasingCompany | null | undefined;
    vehicle_vehicle_drivers: Array<ApiVehicleDriver>;
}

export interface RcaLeasingCompany {
    id: number | null;
    name: string | null;
}

export interface ApiVehicleDriver {
    caen_code: string | null;
    cif_code: string | null;
    cnp: string | null;
    company_name: string | null;
    created_at: string | null;
    deleted_at: string | null;
    driver_license_year: string | null;
    email: string | null;
    first_name: string | null;
    id: number | null;
    is_legal: boolean | null;
    is_owner: boolean | null;
    last_name: string | null;
    nr_ci: string | null;
    phone_number: string | null;
    series_ci: string | null;
    updated_at: string | null;
    user_id: number | null;
    vehicle_id: number | null;
}

export const createDefaultApiVehicleDetails = (): ApiVehicleDetails => {
    return {
        id: null,
        registration_plate: null,
        vin: null,
        manufacture_year: null,
        max_weight: null,
        cylinder: null,
        power: null,
        number_of_seats: null,
        civ_code: null,
        created_at: null,
        updated_at: null,
        deleted_at: null,
        vehicle_rar_category_id: null,
        rca_mapped_category_id: null,
        rca_vehicle_make_id: null,
        rca_vehicle_model_id: null,
        rca_leasing_company_id: null,
        vehicle_fuel_type_id: null,
        vehicle_usage_type_id: null,
        user_id: null,
        ro_vignette_category_id: null,
        hu_vignette_category_id: null,
        vehicle_type_id: null,
        color: null,
        rca_vehicle_make_name: null,
        rca_vehicle_model_name: null,
        vehicle_type: null,
        default_vehicle_address: null,
        vehicle_owner: null,
        default_vehicle_expiration: null,
        rca_vehicle_make: null,
        rca_vehicle_model: null,
        vehicle_fuel_type: null,
        vehicle_usage_type: null,
        rca_mapped_category: null,
        vehicle_vehicle_drivers: [],
        rca_leasing_company: null,
    }
}

export interface ApiVehicleAddress {
    id: number | null | undefined;
    street: string | null;
    street_number: string | null;
    building: string | null;
    entrance: string | null;
    apartment: string | null;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
    country_id: number | null;
    county_id: number | null;
    city_id: number | null;
    vehicle_id: number | null;
    postal_code: string | null;
    county_name: string | null;
    city_name: string | null;
    country: Country | null;
    county: County | null;
    city: City | null;
}

export const createDefaultApiVehicleAddress = (): ApiVehicleAddress => {
    return {
        id: null,
        street: null,
        street_number: null,
        building: null,
        entrance: null,
        apartment: null,
        created_at: null,
        updated_at: null,
        deleted_at: null,
        country_id: null,
        county_id: null,
        city_id: null,
        vehicle_id: null,
        postal_code: null,
        county_name: null,
        city_name: null,
        country: null,
        county: null,
        city: null,
    }
}

export interface ApiVehicleOwner {
    id: number | null;
    last_name: string | null;
    first_name: string | null;
    cnp: string | null;
    cif_code: string | null;
    company_name: string | null;
    series_ci: string | null;
    nr_ci: string | null;
    driver_license_year: string | null;
    is_owner: boolean | null;
    created_at: string | null;
    updated_at: string | null;
    deleted_at: string | null;
    vehicle_id: number | null;
    user_id: number | null;
    caen_code: string | null;
    email: string | null;
    phone_number: string | null;
    is_legal: boolean | null;
}

export const createDefaultApiVehicleOwner = (): ApiVehicleOwner => {
    return {
        id: null,
        last_name: null,
        first_name: null,
        cnp: null,
        cif_code: null,
        company_name: null,
        series_ci: null,
        nr_ci: null,
        driver_license_year: null,
        is_owner: null,
        created_at: null,
        updated_at: null,
        deleted_at: null,
        vehicle_id: null,
        user_id: null,
        caen_code: null,
        email: null,
        phone_number: null,
        is_legal: null,
    }
}

